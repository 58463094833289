import { http } from "api/http";

const sendEmailTemplateService = ({ emails, content }) => {
  console.log(emails, content);
  return http.post(`/communication/sendmail`, { emails, content });
};

const createClientGroupBroadcast = (data: any) => {
  return http.post("communication/create-client-group", data);
};

const getClientGroupBroadcast = ({ queryKey }: any) => {
  const [, params] = queryKey;
return http.get("/communication/get-client-group", { params });
};

const deleteClientGroupBroadcast = (id: number) => {
  return http.delete(`/communication/delete-client-group/${id}`);
};

const updateClientGroupBroadcast = ({ id, data }: any) => {
  console.log(data,id,'from api')
  return http.put(`/communication/update-client-group/${id}`, data);
};

// const getClientGroupBroadcastDetails = (id:any) => {
//   return http.get(`/communication/get-client-groupdetails/${id}`);
// };
const getClientGroupBroadcastDetails = (id: any, search = "", limit?: number, offset?: number) => {
  let url = `/communication/get-client-groupdetails/${id}?search=${search}`;

  if (limit !== undefined) {
    url += `&limit=${limit}`;
  }

  if (offset !== undefined) {
    url += `&offset=${offset}`;
  }

  return http.get(url);
};


const createEmailTemplatessss = (data: any) => {
  return http.post("communication/create-email-template", data);
};

const getEmailTemplates = ({ queryKey }: any) => {
  const [, params] = queryKey;
return http.get("/communication/get-email-template", { params });
};

const deleteEmailTemplate = (id: number) => {
  return http.delete(`/communication/delete-email-template/${id}`);
};

const getOneEmailTemplate = (id:any) => {
  return http.get(`/communication/get-one-emailTemplate/${id}`);
};

const updateEmailTemplate = ({ id, data }: any) => {
  console.log(data,id,'from api')
  return http.put(`/communication/update-email-temlate/${id}`, data);
};
const createBroadcastActivity = (data: any) => {
  return http.post("communication/create-broadcast-activity", data);
};

const uploadFileBroadcast = (data: any) => {
  return http.post("communication/upload-files", data);
};

const getBroadcastActivity = ({ queryKey }: any) => {
  const [, params] = queryKey;
  return http.get("communication/get-brocastactivity", { params });
};

const getBroadcastActivityDetails = (id:any) => {
  return http.get(`/communication/get-broadcastactivity/${id}`);
};
export {
  sendEmailTemplateService,
  createClientGroupBroadcast,
  getClientGroupBroadcast,
  deleteClientGroupBroadcast,
  updateClientGroupBroadcast,
  getClientGroupBroadcastDetails,
  createEmailTemplatessss,
  getOneEmailTemplate,
  updateEmailTemplate,
  getEmailTemplates,
  deleteEmailTemplate,
  createBroadcastActivity,
  getBroadcastActivity,
  uploadFileBroadcast,
  getBroadcastActivityDetails
};
