import Table, { ColumnType } from "components/Table";
import { getBroadcastActivityDetails } from 'api/services/communication';
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ResType } from 'types';
import { getTitle } from 'utils';
import AccessDenied from "../clientGroups/access-denied";
import Loader from "components/Loader";
import { Box, Typography } from "@mui/material";

const BroadcastMessageDetails = () => {
    const [profileData, setProfileData] = useState<any>("")

    const {broadcastActivityId  } = useParams();

    const { data, isLoading, error }: ResType = useQuery(
        ["broadcastactivitydetails"],
        () => getBroadcastActivityDetails(broadcastActivityId),{
            onSuccess: (data) => {
                setProfileData(data?.data || false);
              },
        }
      );

    if (isLoading) return <Loader/>
  return (
    <>
    {!profileData && profileData !=="" &&  (
        <AccessDenied />
    )}
                      {profileData && (
 <Box p={3} pb={0} px={1}>
{/* <Box display="flex" justifyContent="space-between" px={2}> */}
  <Typography  mb={1} variant="subtitle2">Group Name - {data?.data?.groupName}</Typography>
  <Typography  mb={1} variant="subtitle2">Email Template Name - {data?.data?.templateName}</Typography>

    <Table
    sx={{ mt: 2 }}
    data={data?.data?.details || []}
    columns={defaultColumns}
    // pagination={{ totalCount, page, setPage, pageCount, setPageCount }}
    // onRowClick={(rowData) => handleView(rowData.id)}
    />
    </Box>
    // </Box>
                      )}
    </>
  )
}
const defaultColumns = [
    {
        key: "clientName", 
        title: "Client Name",
        render: (rowData) => getTitle(rowData?.clientName),  
    },
    {
        key: "email", 
        title: "Email", 
        render: (rowData) => getTitle(rowData?.email),  
    }
];
export default BroadcastMessageDetails