import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getDeletedClientGroups, getDeletedClients, restoreClient, restoreClientGroup } from "api/services/clients/clients";
import { getDeletedUsers, restoreUser } from "api/services/users";
import Loader from "components/Loader";
import Table from "components/Table";
import { snack } from "components/toast";
import { useConfirm } from "context/ConfirmDialog";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import { handleError } from "utils/handleError";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { exportDeletedClientsGroupPageReport, exportDeletedClientsPageReport } from "api/services/reports";


function DeletedClientGroups(state) {
  
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [clientsData, setClientData] = useState([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const { data, isLoading }: ResType = useQuery(["deleted-client-groups", {
    limit: pageCount,
    offset: page * pageCount
  }], getDeletedClientGroups, {
    onSuccess: (res) => {
      setClientData(res?.data[0]);
      setTotalCount(res?.data[1]);
    },
    onError: (err: any) => {
      if (!err?.error) {
        snack.error(`An error occurred while fetching deleted clients`);
      }
    }
  });

  const { mutate } = useMutation(restoreClientGroup, {
    onSuccess: () => {
      snack.success("Client Access Restored");
      queryClient.invalidateQueries("deleted-client-groups");
    },
    onError: (err: any) => {
      if (err && !err?.error) {
        snack.error(handleError(err));
      } else if (!err?.error) {
        snack.error(`An error occurred while restoring clients`);
      }
    },
  });

  const handleRestore = (id: number) => {
    confirm({
      msg: "Are you sure you want to restore this client?",
      action: () => mutate(id),
    });
    
  };

  const { mutate:mutate1 } = useMutation(exportDeletedClientsGroupPageReport, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "deleted-Clients-group.xlsx";
      link.click();
      snack.success("Exported successfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });
  const handleExport = () => {
    mutate1({
      ...state,
      
    });

  }

  if (isLoading) return <Loader />;

  return (
    <Box>
    <Box display="flex" gap={3} justifyContent="space-between" mt={2} mr={2} mb={1}>
      <Typography variant="subtitle1" px={2}>
        Deleted Client Groups
      </Typography>
      <Button variant="outlined" color="secondary" onClick={handleExport}
            startIcon={<FileDownloadOutlinedIcon />}     >
            Export
          </Button>
          </Box>
      <Box px={1}>
        <Table
          sx={{ mb: 0, pb: 0 }}
          loading={isLoading}
          data={clientsData || []}
          columns={[
            {
              key: "clientId",
              title: "Client Id",
            },
            {
              key: "displayName",
              title: "Display Name",
            },
            {
              key: "mobileNumber",
              title: "Mobile Number",
            },
            {
              key: "email",
              title: "Email ID",
            },
            {
              key: "Action",
              title: "Action",
              render: (item: any) => {
                return (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRestore(item?.id)}
                  >
                    Restore
                  </Button>
                );
              },
            },
          ]}
          pagination={{ totalCount, page, setPage, pageCount, setPageCount }}
        />
      </Box>
    </Box>
  );
}

export default DeletedClientGroups;
