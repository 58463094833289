import { Add, Close } from "@mui/icons-material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { getClients, getClientsGroup } from "api/services/clients/clients";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import FloatingButton from "components/FloatingButton";
import SearchContainer from "components/SearchContainer";
import Table, { ColumnType } from "components/Table";
import ValidateAccess from "components/ValidateAccess";
import { CLIENT_CATEGORIES } from "data/constants";
import { Permissions } from "data/permissons";
import useTitle from "hooks/useTitle";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ResType } from "types";
import { getTitle } from "utils";
import Actions from "views/clients-group/Actions";
import AddClient from "views/clients/AddClient";
import CustomizeColumns from "views/clients/CustomizeColumns";
import ClientFilter from "views/clients/Filter";
import ImportClients from "views/clients/ImportClients";
import AppliedFilters from "views/tasks/Filters/ApplidedFilters";
import { StyledAppliedFilterItem } from "views/tasks/Filters/styles";
import { resetFilters } from "redux/reducers/taskboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { snack } from "components/toast";
import { exportClientsGroupPageReport, exportClientsReport } from "api/services/reports";
import { handleError } from "utils/handleError";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { selectClient } from "redux/reducers/clientsSlice";
import { handleFieldChange, resetState } from "redux/reducers/clientsSlice";
import AddClientGroup from "views/clients/AddClient/addClientGroup";

function ClientsGroup(state) {
  const { mutate } = useMutation(exportClientsGroupPageReport, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "Clients.xlsx";
      link.click();
      snack.success("Exported successfully");
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });

  useTitle("Clients Group");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
  const [openCustomColumns, setOpenCustomColumns] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [clients, setClients] = useState<any[]>([]);
  const [clientGroup, setClientGroup] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [columns, setColumns] = useState(_.cloneDeep(defaultGroupColumns));
  const states = useSelector(selectClient);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetFilters());
    }, 300);
    return () => {
      dispatch(resetFilters());
      dispatch(resetState());
    };
  }, []);

  const { data: clientGroupData, isLoading: clientGroupLoading }: ResType = useQuery(
    [
      "clients-group",
      {
        limit: pageCount,
        offset: page * pageCount,
        query: {
          ...states.appliedFilters,
        }
      },
    ],
    getClientsGroup,
    {
      onSuccess: (res: any) => {
        const result = partition(res?.data?.result, (el: any) => el.status === "INACTIVE");
        setClientGroup(result);
      },
      onError: (err: any) => {
        if (!err?.error) {
          snack.error("An error occurred while fetching client-data");
        }
      },
    }
  );

  const partition = (arr: any, condition: any) => {
    const actives = arr.filter((el: any) => condition(el));
    const inactives = arr.filter((el: any) => !condition(el));
    return [...inactives, ...actives];
  };

  const handleRowClick = (v: any) => {
    navigate(`/clients/${v?.id}/profile`);
  };

  const handleRowGroupClick = (v: any) => {
    navigate(`/clients-group/${v?.id}/group-profile`);
  };

  const onRemoveFilter = (filterKey: any, filterItemIndex: any) => {
    const appliedFilters = JSON.parse(JSON.stringify(states))[filterKey].filter(
      (item: any, index: number) => index !== filterItemIndex
    );
    dispatch(resetFilters());

  };

  const totalCount = clientGroupData?.data?.count;

  const getfilterkeys = (filters: any) => {
    const obj = getfilterkeysObj(filters);
    return Object.keys(obj);
  };

  const getfilterkeysObj = (filters: any) => {
    const obj = JSON.parse(JSON.stringify(filters));
    delete obj?.search;
    delete obj?.monthAdded;
    return obj;
  };

  const handleExport = () => {
    mutate({
      ...states.appliedFilters,
      category: states?.appliedFilters?.category["value"] === undefined ? [] : [states?.appliedFilters?.category["value"]],
      subCategory: states?.appliedFilters?.subCategory.map((c: any) => c?.value),
      labels: states?.appliedFilters?.labels.map((c: any) => c?.id),
      status: states?.appliedFilters?.status["value"] === undefined ? [] : [states?.appliedFilters?.status["value"]],
    },
    );
  };

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} md={12} sm={12} lg={12} sx={{ mt: { xs: theme.spacing(3), sm: 0 } }}> {/* Add margin top based on screen size */}
        <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'flex-start', sm: 'center' }} justifyContent="space-between">
          <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'flex-start', sm: 'center' }} justifyContent="space-between">
            <SearchContainer
              value={states.appliedFilters.search}
              debounced
              minWidth={isSmallScreen ? "250px" : "300px"} // Adjust the values as needed
              onChange={(v) => {
                dispatch(handleFieldChange({ key: "search", value: v }));
                setPage(0);
              }}
              placeHolder="Search by Display Name | PAN ..."
            />
          </Box>
          <Box display="flex" gap={2} flexWrap="wrap" justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}>
            {getfilterkeys(states).map((filter, filterIndex: number) => {
              if (getfilterkeysObj(states)[filter].length > 0) {
                return (
                  <Box display="flex" gap={1} alignItems="center" key={filterIndex}>
                    <Typography variant="caption">{getTitle(filter)}:</Typography>
                    <Box display="flex" gap="4px" alignItems="center" flexWrap="wrap">
                      {getfilterkeysObj(states)[filter].map((item: any, filterItemIndex: number) => (
                        <StyledAppliedFilterItem
                          onClick={() => {
                            onRemoveFilter(filter, filterItemIndex);
                          }}
                          key={filterItemIndex}
                        >
                          <Typography variant="body2">
                            {filter == "monthAdded" ? item : item?.label}
                            {filter == "labels" && item?.name}
                          </Typography>
                          <Close sx={{ fontSize: "11px" }} />
                        </StyledAppliedFilterItem>
                      ))}
                    </Box>
                  </Box>
                );
              }
              return null;
            })}
          </Box>
          <Box display="flex" gap={2} flexWrap="wrap">
            <Button
              onClick={() => navigate('/clients')}
              variant="outlined"
              color="secondary"
            >
              Clients
            </Button>
            {selected.length > 0 && (
              <ValidateAccess name={[Permissions.DELETE_CLIENT_GROUPS, Permissions.EDIT_CLIENT_GROUPS]}>
                <Button
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  variant="outlined"
                  color="secondary"
                  endIcon={<KeyboardArrowDownOutlinedIcon />}
                >
                  Actions
                </Button>
              </ValidateAccess>
            )}
            {/* <Button
              onClick={() => setOpenAddGroup(true)}
              variant="outlined"
              startIcon={<Add />}
              color="secondary"
            >
              Client Group
            </Button> */}
            <Button variant="outlined" color="secondary" onClick={handleExport}
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Export
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} >
          <Table
            sx={{ mt: 3 }}
            loading={clientGroupLoading}
            onRowClick={handleRowGroupClick}
            data={clientGroup || []}
            columns={defaultGroupColumns}
            selection={{ selected, setSelected }}
            pagination={{ totalCount, page, setPage, pageCount, setPageCount }}
          />
        </Grid>
      <ValidateAccess name={Permissions.CREATE_CLIENT_GROUPS}>
        <FloatingButton
          onClick={() => {
            setOpenAddGroup(true);
          }}
        />
      </ValidateAccess>

      <AddClient open={open} setOpen={setOpen} />
      <ImportClients open={openImportDialog} setOpen={setOpenImportDialog} />
      <ClientFilter
        open={openFilter}
        setOpen={setOpenFilter}
      />

      <CustomizeColumns
        defaultColumns={_.cloneDeep(defaultGroupColumns)}
        columns={columns}
        setColumns={setColumns}
        open={openCustomColumns}
        setOpen={setOpenCustomColumns}
      />
      <Actions
        clearSelection={() => setSelected([])}
        selected={selected}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setSelected={setSelected}
      />
      <AddClientGroup
        open={openAddGroup}
        setOpen={setOpenAddGroup}
        watch={false}
        setValue={false}
      />
    </Grid>
  );
}

const defaultGroupColumns: Array<ColumnType> = [
  { key: "clientId", title: "Client Group ID", default: true },
  { key: "panNumber", title: "GSTIN / PAN", default: true, render: (rowData) => (rowData?.gstNumber || rowData?.panNumber) },
  { key: "clientNumber", title: "Client Number", default: true },
  { key: "displayName", title: "Display Name", default: true },
  { key: "tradeName", title: "Trade Name", hide: true },
  { key: "mobileNumber", title: "Mobile Number", default: true },
  { key: "email", title: "Email ID", default: true },
  { key: "authorizedPerson", title: "Authorized Person", hide: true },
  {
    key: "active",
    title: "Status",
    default: true,
    render: (rowData) => {
      return (
        <div>
          {rowData.status === "ACTIVE" ? (
            <span style={{ color: "green" }}>Active</span>
          ) : (
            <span style={{ color: "red" }}>Inactive</span>
          )}
        </div>
      );
    },
  },
];

export default ClientsGroup;