export interface ParticularChangeType {
  index: number;
  key: string;
  value: any;
}

export interface OtherParticularChangeType {
  id: number;
  index: number;
  key: string;
  value: any;
  taskExpenseType: string;
}

export interface Address {
  displayName: string;
  legalName: string;
  buildingNumber: string;
  floorNumber: string;
  district: string;
  location: string;
  buildingName: string;
  street: string;
  city: string;
  state: string;
  pincode: string;
  email: string;
  mobileNumber: string;
  billingfulladdress: any;
  countryCode: string;
}

export interface Particular {
  name: string;
  hsn: string;
  units: number;
  rate: number;
  discountType: "PERCENT" | "AMOUNT";
  discount: number;
  amount: number;
  gst: string | null | {};
  taskId?: number;
  id?: number;
}

export interface OtherParticular {
  id: number;
  name: string;
  amount: number;
  taskExpenseType: string;
}

export interface BankDetails {
  accountNumber: string;
  bankName: string;
  branchName: string;
  ifscCode: string;
  upiId: string;
  upiAttachment: string;
  upiAttachmentId: string;
}

export interface IState {
  type: "INVOICE" | "PROFORMA";
  billingEntity: number | null;
  locationOfSupply: string;
  proformaLocationOfSupply: string;
  approvalHierarchy: any;
  client: string | null;
  clientType: string | null;
  selectedItems: number | null;
  placeOfSupply: string;
  estimateNumberType: "AUTO_GENERATED" | "MANUAL";
  autoGeneratedNumber: string;
  estimateNumber: string;
  estimateDate: string | null;
  estimateDueDate: string | null;
  terms: string | null;
  billingEntityAddress: Address | null;
  shippingAddress: Address | null;
  billingAddress: Address | null;
  particulars: Array<Particular>;
  otherParticulars: Array<OtherParticular>;
  bankDetails: BankDetails | null;
  termsAndConditionsCopy: Array<string>;
  adjustment: number;
  interState: boolean;
  whatsappCheck: boolean;
  emailCheck: boolean;
  prifix: string;
  invNumber: string;
  hasGst: boolean;
  showClientData: boolean;
  showDiscount: boolean;
  fromTask: boolean;
  createdAt: string;
  logoUrl: string;
  signatureUrl: string;
  selected: any[];
  proformaId: number | null;
  divideTax: boolean;
  supplyType: "PAYMENT_OF_IGST" | "UNDER_BOND";
}

export let initialParticular: Particular = {
  name: "",
  hsn: "",
  units: 1,
  rate: 0,
  discountType: "PERCENT",
  discount: 0,
  amount: 0,
  gst: { desc: "", name: "GST18 (18%)", value: "GST18" },
};

export const initialState: IState = {
  type: "INVOICE",
  createdAt: "",
  hasGst: false,
  showClientData: false,
  showDiscount: false,
  fromTask: false,
  billingEntity: null,
  locationOfSupply: "",
  proformaLocationOfSupply: "",
  billingEntityAddress: null,
  approvalHierarchy: null,
  client: null,
  clientType: null,
  selectedItems: null,
  placeOfSupply: "",
  estimateNumberType: "AUTO_GENERATED",
  autoGeneratedNumber: "",
  estimateNumber: "",
  estimateDate: null,
  estimateDueDate: null,
  terms: null,
  shippingAddress: null,
  billingAddress: null,
  particulars: [],
  otherParticulars: [],
  bankDetails: null,
  termsAndConditionsCopy: [],
  adjustment: 0,
  interState: false,
  whatsappCheck: false,
  emailCheck: false,
  prifix: "",
  invNumber: "",
  logoUrl: "",
  signatureUrl: "",
  selected: [],
  proformaId: null,
  divideTax: false,
  supplyType: "PAYMENT_OF_IGST",
};
