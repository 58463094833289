import { Autocomplete, Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { getOrganization, updateSmtp, updateSmtpPreferences } from "api/services/organization";
import { gmail, outlook, yahoo } from "assets";
import Loader from "components/Loader";
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";

function SMTPPreferences({ stateMails, setStateMail, isLoading }) {
    const queryClient = useQueryClient();

    const handleChange = (name: string, newValue: any) => {
        const selectedSmtp = newValue;
        setStateMail({
            ...stateMails,
            [name]: selectedSmtp,
        });
    };



    const { mutateAsync, isLoading: updateLoading } = useMutation(updateSmtpPreferences, {
        onSuccess: () => {
            snack.success("The activation of your SMTP server configuration is scheduled for tomorrow !");
            queryClient.invalidateQueries("organization");
        },
        onError: (err: any) => {
            if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
                snack.error(err.response.data.message);
            } else if (!err?.error) {
                snack.error(`An error occurred while Updating Organization Profile`);
            }
        },
    });

    const handleSumit = () => {
        const data = stateMails
        mutateAsync({ data })
    }

    if (isLoading) return <Loader />;

    return <>
        <Box sx={{ mt: 5 }}>
            <Typography variant="subtitle2">Trigger Points</Typography>
            <Box sx={{ m: 1, ml: 3, width: "700px", mt: 3 }}>
                <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                    <Typography width="200px" textAlign="right" sx={{ mr: 2 }}>Broadcast</Typography>
                    <Autocomplete
                        fullWidth
                        options={(stateMails?.smtp ? Object.entries(stateMails?.smtp ? stateMails.smtp : {}) : []) || []}
                        getOptionLabel={(option: any) => option ? option[1]?.auth?.user : ''}
                        value={Object.entries(stateMails?.smtp ? stateMails.smtp : {}).find(([key, item]) => {
                            const broadcastSmtp = stateMails?.broadcastSmtp || [];
                            return broadcastSmtp[0] && key === broadcastSmtp[0];
                        }) || null}
                        onChange={(event, newValue: any) => {
                            handleChange('broadcastSmtp', newValue);
                        }}
                        isOptionEqualToValue={(option: any, value) => {
                            return option[0] === value
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Broadcast SMTP"
                                fullWidth
                                size="small"
                                name="broadcastSmtp"
                            />
                        )}
                    />
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                    <Typography width="200px" textAlign="right" sx={{ mr: 2 }}>Client</Typography>
                    <Autocomplete
                        fullWidth
                        options={Object.entries(stateMails?.smtp ? stateMails.smtp : {}) || []}
                        getOptionLabel={(option: any) => option ? option[1]?.auth?.user : ''}
                        value={Object.entries(stateMails?.smtp ? stateMails.smtp : {}).find(([key, item]) => {
                            const clientSmtp = stateMails?.clientSmtp || [];
                            return clientSmtp[0] && key === clientSmtp[0];
                        }) || null}
                        onChange={(event, newValue) => {
                            handleChange('clientSmtp', newValue);
                        }}
                        isOptionEqualToValue={(option: any, value) => option[0] === value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Client SMTP"
                                fullWidth
                                size="small"
                                name="clientSmtp"
                            />
                        )}
                    />
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                    <Typography width="200px" textAlign="right" sx={{ mr: 2 }}>I-Pro</Typography>
                    <Autocomplete
                        fullWidth
                        options={Object.entries(stateMails?.smtp ? stateMails.smtp : {}) || []}
                        getOptionLabel={(option: any) => option ? option[1].auth.user : ''}
                        value={Object.entries(stateMails?.smtp ? stateMails.smtp : {}).find(([key, item]) => {
                            const iproSmtp = stateMails?.iproSmtp || [];
                            return iproSmtp[0] && key === iproSmtp[0];
                        }) || null}
                        onChange={(event, newValue) => {
                            handleChange('iproSmtp', newValue);
                        }}
                        isOptionEqualToValue={(option: any, value) => option[0] === value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="I-Pro SMTP"
                                fullWidth
                                size="small"
                                name="iproSmtp"
                            />
                        )}
                    />
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                    <Typography width="200px" textAlign="right" sx={{ mr: 2 }}>Billing</Typography>
                    <Autocomplete
                        fullWidth
                        options={Object.entries(stateMails?.smtp ? stateMails.smtp : {}) || []}
                        getOptionLabel={(option: any) => option ? option[1].auth.user : ''}
                        value={Object.entries(stateMails?.smtp ? stateMails.smtp : {}).find(([key, item]) => {
                            const billingSmtp = stateMails?.billingSmtp || [];
                            return billingSmtp[0] && key === billingSmtp[0];
                        }) || null}
                        onChange={(event, newValue) => {
                            handleChange('billingSmtp', newValue);
                        }}
                        isOptionEqualToValue={(option: any, value) => option[0] === value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="billing SMTP"
                                fullWidth
                                size="small"
                                name="billingSmtp"
                            />
                        )}
                    />
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                    <Typography width="200px" textAlign="right" sx={{ mr: 2 }}>All Other Notifications</Typography>
                    <Autocomplete
                        fullWidth
                        options={Object.entries(stateMails?.smtp ? stateMails.smtp : {}) || []}
                        getOptionLabel={(option: any) => option ? option[1]?.auth?.user : ''}
                        value={Object.entries(stateMails?.smtp ? stateMails.smtp : {}).find(([key, item]) => {
                            const othersSmtp = stateMails?.othersSmtp || [];
                            return othersSmtp[0] && key === othersSmtp[0];
                        }) || null}
                        onChange={(event, newValue) => {
                            handleChange('othersSmtp', newValue);
                        }}
                        isOptionEqualToValue={(option: any, value) => option[0] === value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Others SMTP"
                                fullWidth
                                size="small"
                                name="othersSmtp"
                            />
                        )}
                        clearOnEscape={false}
                        clearIcon={null}
                    />
                </Box>
                <Box display="flex" sx={{ mb: 2 }}>
                    <Typography width="170px"></Typography>
                    <Button
                        onClick={handleSumit}
                        disabled={isLoading}
                        size="large"
                        color="secondary"
                        variant="contained"
                    >
                        Update
                    </Button>
                </Box>
            </Box>
        </Box>
    </>;
}

export default SMTPPreferences;