import {
    Box,
    Button,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  import {
    getOneEmailTemplate,
    updateEmailTemplate,
    uploadFileBroadcast,
  } from "api/services/communication";
  import React, { useState, useEffect, useRef } from "react";
  import { useMutation, useQuery, useQueryClient } from "react-query";
  import { Outlet, useNavigate, useParams } from "react-router-dom";
  import SunEditor from "suneditor-react";
  import { ResType } from "types";
  import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
  } from "suneditor/src/plugins";
  import "suneditor/dist/css/suneditor.min.css";
  import { snack } from "components/toast";
  import { handleError } from "utils/handleError";
import { getLabels } from "api/services/labels";
import AccessDenied from "pages/communication/clientGroups/access-denied";
  type SunEditorInstance = {
    setContents: (content: string) => void;
  };
  
  const EmailTemplateEdit = () => {
    const [title, setTitle] = useState("");
    const [tags, setTags] = useState([]);
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState<any>("");
    const [pageCount, setPageCount] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [profileData, setProfileData] = useState<any>("")

    const navigate = useNavigate();

    const editorRef = useRef<SunEditorInstance | null>(null);

    const queryClient = useQueryClient();
    const { emailTemplateId } = useParams();
    const { data:labels }: ResType = useQuery(["labels", {
      limit: pageCount,
      offset: page * pageCount
    }], getLabels, {
      onSuccess: (res) => {
        // setTotalCount(res?.data[1])
        console.log(res?.data,'res')
        console.log(tags,)
      }
    });
    const { data, isLoading, error }: ResType = useQuery(
      ["clientgroupbroadcast", emailTemplateId],
      () => getOneEmailTemplate(emailTemplateId),
      {
        onSuccess: (data) => {
          setTitle(data?.data?.title);
          setTags(data?.data?.labels);
          setSubject(data?.data?.subject);
          setContent(data?.data?.content);
          setProfileData(data?.data || false)

          if (editorRef.current) {
            editorRef.current.setContents(data?.data?.content);
          }
        },
      }
    );
  
    const { mutate: updateEmailTemplatee } = useMutation(updateEmailTemplate, {
      onSuccess: () => {
        snack.success("Email Template Updated successfully");
        queryClient.invalidateQueries("clientgroupbroadcast");
         navigate("/communication/email")
        
      },
      onError: (err: any) => {
        snack.error(handleError(err));
      },
    });

    const { mutateAsync:storageData } = useMutation(uploadFileBroadcast, {

      onSuccess: (res: any) => {
        // onChange(1, res?.data);
        console.log(res,'storage')
      },
      // onError: (err: any) => {
    
      //   snack.error(handleError(err));
      // },
    });
  

    function base64ToBlob(base64, contentType) {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      
      // Create a file name using a timestamp or any other naming convention
      const fileName = `image_${Date.now()}.png`;
    
      // Create a File object instead of a Blob
      return new File([byteArray], fileName, { type: contentType });
    }
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      if (!title) {
        snack.error("Please enter title field");
      } else if (!subject) {
        snack.error("Please enter subject field");
      } else if (!content) {
        snack.error("Please enter content field");
      }
      
      // Step 1: Parse the content and extract images
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const images = Array.from(doc.querySelectorAll<HTMLImageElement>('img'));
    
      // Step 2: Array to hold promises for image uploads
      const uploadPromises = images.map(async (img) => {
        const src = img.src;
    
        // Step 3: Process only images with base64 data
        if (src.startsWith('data:image/')) {
          const [contentType, base64] = src.split(';base64,');
          // const blob = base64ToBlob(base64, contentType.split(':')[1]);
          const file = base64ToBlob(base64, contentType.split(':')[1]);
    
          const formData = new FormData();
          formData.append('file', file);
          console.log(file,'blob')
    
          try {
            // Step 4: Upload image and replace src with the URL
            const response = await storageData(formData);
            const url = response?.data?.Location;
    
            if (url) {
              img.src = url; // Replace base64 with the URL
            } else {
              snack.error("Image upload failed");
            }
          } catch (error) {
            console.error('Image upload failed:', error);
            snack.error("Image upload failed");
          }
        }
      });
    
      // Step 5: Wait for all image uploads to complete
      await Promise.all(uploadPromises);
    
      // Step 6: Serialize the updated content back to a string
      const updatedContent = doc.body.innerHTML;
    
      // Step 7: Send the updated content as part of your payload
      const payload = {
        title,
        tags: tags,
        content: updatedContent,
        subject
      };
    
      updateEmailTemplatee({ id: emailTemplateId, data: payload });
    };
    const handleContentChange = (content: any) => {
      setContent(content);
    };

    const handleDropdownChange = (e: any) => {
      setTags(e.target.value);
    };
  
    return (
      <>
     {!profileData && profileData !=="" &&  (
      <AccessDenied />
  )}
  
                {profileData && (
<>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            mt={2}
            display="flex"
            flexDirection="column"
            px={3}
          >
            <Box mt={2}>
              <InputLabel id="title">Template Title *</InputLabel>
              <TextField
                name="title"
                size="small"
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                sx={{ width: "50%" }}
                required
              />
            </Box>
            <Box mt={2}>
              <InputLabel id="labels">Label /Tag</InputLabel>

              <Select
                  value={tags}
                  onChange={handleDropdownChange}
                  sx={{ width: "460px", height: "40px" }}
                >
                  {labels?.data[0].map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
            </Box>
            <Box mt={2}>
              <InputLabel id="subject">Subject *</InputLabel>
              <TextField
                name="subject *"
                size="small"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                sx={{ width: "50%" }}
                required
              />
            </Box>
          </Grid>
          <Grid sx={{ mt: "20px",width:"1000px" }}>
            <SunEditor
              setOptions={{
                height: "200",
                placeholder: "Enter your text here!!!",
                defaultStyle: "font-color:red",
                plugins: [
                  align,
                  font,
                  fontColor,
                  fontSize,
                  formatBlock,
                  hiliteColor,
                  horizontalRule,
                  lineHeight,
                  list,
                  paragraphStyle,
                  table,
                  template,
                  textStyle,
                  image,
                  link,
                ],
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize", "formatBlock"],
                  ["paragraphStyle"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                  ],
                  ["fontColor", "hiliteColor"],
                  ["removeFormat"],
                  "/", // Line break
                  ["outdent", "indent"],
                  ["align", "horizontalRule", "list", "lineHeight"],
                  ["table", "link", "image"],
                ],
                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                font: [
                  "Arial",
                  "Calibri",
                  "Comic Sans",
                  "Courier",
                  "Garamond",
                  "Georgia",
                  "Impact",
                  "Lucida Console",
                  "Palatino Linotype",
                  "Segoe UI",
                  "Tahoma",
                  "Times New Roman",
                  "Trebuchet MS",
                ],
              }}
              defaultValue={content}
              onChange={handleContentChange}
              getSunEditorInstance={(editor) => {
                editorRef.current = editor;
              }}
            />
          </Grid>
          <Box flex={1}>
            <Outlet />
          </Box>
        </form>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            sx={{ mr: 32, mt: 1 }}
          >
            Update Template
          </Button>
        </Box>
        </>
                )}
      </>
    );
  };
  
  export default EmailTemplateEdit;
  