import React, { useState, useEffect } from 'react';
import { Box, Grid, MenuItem, Select, FormControl, InputLabel, Tooltip, Typography, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createClient, getClientGroup } from 'api/services/clients/clients';
import { getUsers } from 'api/services/users';
import DrawerWrapper from 'components/DrawerWrapper';
import FormInput from 'components/FormFields/FormInput';
import FormSelect from 'components/FormFields/FormSelect';
import Loader from 'components/Loader';
import LoadingButton from 'components/LoadingButton';
import { snack } from 'components/toast';
import { CLIENT_CATEGORIES } from 'data/constants';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DialogProps, ResType } from 'types';
import { createClientDefaultValues, CreateClientSchema } from 'validations/createCllient';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useConfirm } from 'context/ConfirmDialog';
import ValidateAccess from 'components/ValidateAccess';
import { Permissions } from 'data/permissons';
import { getClientPrefixId } from 'api/services/invoice.preferencess';
import FormRadio from 'components/FormFields/FormRadio';
import countries from 'data/countries';
import Flag from 'react-world-flags';
import AddClientGroup from './addClientGroup';
import { Add } from '@mui/icons-material';
import { handleError } from 'utils/handleError';


interface Props extends DialogProps {
  successCb?: () => void;
}

interface Country {
  code: any;
  name: string;
  dialCode: string;
};


const AddClient: React.FC<Props> = ({ open, setOpen, successCb }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [groupList, setGroupList] = useState([]);

  const { data: clientGroup, isLoading: clientGroupLoading }: ResType = useQuery("client-group", getClientGroup, {
    enabled: open,
    onSuccess: (res) => {
        setGroupList(res?.data?.result);
    },
    onError: (err) => {
        if (!err?.error) {
            snack.error(`An error occurred while fetching user-data`);
        }
    }
  });

  const { data: users, isLoading: userLoading }: ResType = useQuery("users", getUsers, {
    enabled: open,
    onError: (err) => {
      if (!err?.error) {
        snack.error(`An error occurred while fetching user-data`);
      }
    }
  });

  const { data: clientPrefixId, isLoading: clientPrefixIdLoading }: ResType = useQuery("client-Prefix-Id", getClientPrefixId, {
    enabled: open,
  });

  const { mutate, isLoading } = useMutation(createClient, {
    onSuccess: (res: any) => {
      snack.success("Client Created");
      setOpen(false);
      if (successCb) {
        successCb();
        return;
      }
      reset(createClientDefaultValues);
      navigate({
        pathname: `/clients/${res.data.id}/profile/`,
        search: `?displayName=${res.data?.displayName}&clientId=${res.data?.clientId}`,
      });
    },
    onError: (err: any) => {
      if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
        if (err.response.data.message === "Client Number Exists") {
          confirm({
            msg: "Assigning unique client number improves recordkeeping. However, for related clients, using the same number can be helpful for tracking purposes.  Would you like to create a unique number for this client, or proceed with the current number?",
            action: () => {
              const allFormData: any = getValues();
              mutate({
                ...allFormData,
                clientManager: allFormData?.clientManager?.value,
                gstVerified: allFormData?.gstRegistered === "yes" && allFormData?.gstVerified,
                panVerified: allFormData?.gstRegistered === "no" && allFormData?.panVerified,
                clientPortalAccess: allFormData?.clientPortalAccess === "yes",
                clientNumberDuplicate: false
              });
            },
          });
        } else {
          snack.error(handleError(err));
        }
      } else if (!err.error) {
        snack.error('An error occurred while creating Client')
      }
    },
  });

  const subCategoriesExist = (category: any) => {
    let foundCategory = CLIENT_CATEGORIES.find((item: any) => item.value === category);
    return foundCategory?.subCategories?.length;
  };

  const { watch, control, handleSubmit, reset, getValues, setValue } = useForm({
    defaultValues: createClientDefaultValues,
    mode: "onChange",
    resolver: yupResolver(CreateClientSchema({ subCategoriesExist })),
  });

  const setData = (data: any) => {
    reset({
      ...getValues(),
      ...data,
    });
  };

  let subCategories = CLIENT_CATEGORIES.find(
    (item) => item.value === watch("category")
  )?.subCategories;

  const onFormSubmit = (data: any) => {
    if (!data.displayName.trim().length) {
      snack.error("Invalid Display Name");
    } else {
      mutate({
        ...data,
        clientManager: data?.clientManager?.value,
        gstVerified: data?.gstRegistered === "yes" && data?.gstVerified,
        panVerified: data?.gstRegistered === "no" && data?.panVerified,
        clientPortalAccess: data?.clientPortalAccess === "yes",
        clientNumberDuplicate: true,
      });
    }
  };

  useEffect(() => {
    if (clientPrefixId) {
      setValue("clientNumber", clientPrefixId?.data?.prefix);
    }
  }, [clientPrefixId, setValue]);

  useEffect(() => {
    setValue("mobileNumber", '')
  }, [watch("countryCode"), setValue]);

  return (
    <DrawerWrapper
      open={open}
      setOpen={() => {
        reset(createClientDefaultValues);
        setOpen(false);
      }}
      title="Add Client"
    >
      {userLoading || clientGroupLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid item height={"100vh"} overflow={"scroll"} >
            <FormSelect
              control={control}
              required
              name="category"
              label="Category"
              options={CLIENT_CATEGORIES.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
            />
            {subCategories && (
              <Box mt={2}>
                <FormSelect
                  control={control}
                  required
                  name="subCategory"
                  label="Sub Category"
                  options={subCategories.map((item) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                />
              </Box>
            )}
            <Box mt={2} display={"flex"}>
              <Box sx={{ width: "90%"}} mr={1}>
              <FormSelect
                control={control}
                required
                name="clientGroup"
                label="Client Group"
                options={groupList.map((item: any) => ({
                  label: item.displayName,
                  value: item.id,
                }))}
              />
              </Box>
              <ValidateAccess name={Permissions.CREATE_CLIENT_GROUPS}>
              <Button variant='outlined' sx={{width:"10%"}} onClick={() => setOpenAddGroup(!openAddGroup)} color='secondary'><Add /></Button>
              </ValidateAccess>
            </Box>
            <Box mt={2}>
              <FormInput control={control} name="displayName" label="Display Name" required inputProps={{ maxLength: 100 }} />
            </Box>
            <Box mt={2}>
              <FormInput
                control={control}
                name="clientNumber"
                label="Client Number"
                inputProps={{
                  maxLength: 31,
                }}
              />
            </Box>
            <Box display='flex'>
              <Box mt={2} width='70%' mr={1}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="country-label">Country</InputLabel>
                  <Controller
                    name="countryCode"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="country-label"
                        label="Country"
                        onChange={(e) => {
                          const selectedCountry = countries.find((c) => c.code === e.target.value);
                          field.onChange(e);
                          if (selectedCountry) {
                            setValue('dialCode', selectedCountry?.phone);
                          }
                        }}
                      >
                        {countries.map((country, index) => (
                          <MenuItem key={index} value={country.code}>
                            <Flag code={country.code} style={{ width: 24, marginRight: 8 }} />
                            {country.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
              <Box mt={2} width='30%'>
                <FormInput
                  disable={true}
                  control={control}
                  name="dialCode"
                  label="Dial Code"
                  required
                />
              </Box>
            </Box>
            <Box mt={2}>
              <FormInput
                control={control}
                name="mobileNumber"
                label="Mobile Number"
                type='number'
                required
                inputProps={{
                  maxLength: 15,
                }}
                InputProps={
                  <Tooltip title="Please enter the Mobile Number you use for WhatsApp">
                    <Typography sx={{ display: "flex" }}>
                      <span style={{ color: "#B2BEB5" }}>|</span>&nbsp;
                      <WhatsAppIcon style={{ color: "#25D366" }} />
                    </Typography>
                  </Tooltip>
                }
              />
            </Box>
            <Box mt={2}>
              <FormInput control={control} name="email" label="Email ID" required />
            </Box>
            <Box mt={2}>
              <FormInput control={control} name="authorizedPerson" label="Authorized Person" inputProps={{ maxLength: 50 }} />
            </Box>
            <Box mt={2}>
              <FormInput control={control} name="designation" label="Designation" inputProps={{ maxLength: 50 }} />
            </Box>
            <Box mt={2}>
              <ValidateAccess name={Permissions.CLIENT_PORTAL_ACCESS}>
                <FormRadio
                  row
                  control={control}
                  name="clientPortalAccess"
                  label="Client Portal Access"
                  options={[
                    { value: "yes", label: "Enable" },
                    { value: "no", label: "Disable" },
                  ]}
                />
              </ValidateAccess>
            </Box>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading}
              fullWidth
              sx={{ mt: 3 }}
              type="submit"
              loadingColor="white"
              title="Create Client"
              color="secondary"
            />
          </Grid>
        </form>
      )}
      <AddClientGroup open={openAddGroup} setOpen={setOpenAddGroup} watch={watch} setValue={setValue} />
    </DrawerWrapper>
  );
}

export default AddClient;